<template>
  <div>
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-form>
              <b-row>
                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-group
                    label="Chương trình đào tạo/Bồi dưỡng"
                    label-for="trainingSystemId"
                  >
                    <v-select
                      v-model="filter.trainingSystemId"
                      :options="trainingSystems"
                      :reduce="option => option.value"
                      :disabled="disabledSelect"
                      @input="onTrainingSystemsChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-group
                    label="Khóa học"
                    label-for="courseId"
                  >
                    <v-select
                      v-model="filter.courseId"
                      :options="courses"
                      :reduce="option => option.value"
                      :disabled="disabledSelect"
                      @input="onCoursesChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-group
                    label="Học kỳ"
                    label-for="courseSemesterId"
                  >
                    <v-select
                      v-model="filter.courseSemesterId"
                      :options="courseSemesters"
                      :reduce="option => option.value"
                      :disabled="disabledSelect"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="3">
                  <b-form-group
                    label="Mã lớp độc lập"
                    label-for="creditClassCode"
                  >
                    <b-form-input
                      id="creditClassCode"
                      v-model="filter.code"
                      name="creditClassCode"
                      @input="onCreditClassCodeChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label="Khoa/Bộ môn"
                    label-for="departmentId"
                  >
                    <v-select
                      v-model="filter.departmentId"
                      :options="departments"
                      :reduce="option => option.value"
                      :disabled="disabledSelect"
                      @input="onDepartmentChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="5"
                >
                  <b-form-group
                    label="Học phần"
                    label-for="subjectId"
                  >
                    <v-select
                      v-model="filter.subjectId"
                      :options="dropdownSubjects"
                      :reduce="option => option.value"
                      :disabled="disabledSelect"
                      @input="onSubjectChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-group
                    label="Lớp độc lập"
                    label-for="creditClassId"
                  >
                    <v-select
                      v-model="creditClassId"
                      :options="creditClasses"
                      :reduce="option => option.value"
                      :disabled="disabledSelect"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
            <b-row>
              <b-col cols="12">
                <div class="d-flex">
                  <div class="mr-auto">
                    <b-form-checkbox
                      v-model="filter.firstExamTime"
                      :value="1"
                      :unchecked-value="0"
                    >
                      Tính theo điểm thi lần 1
                    </b-form-checkbox>
                  </div>
                  <b-button
                    v-show="creditClassId > 0"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    style="margin-right: 5px"
                    @click="onSelect"
                  >
                    Danh sách
                  </b-button>
                  <b-button
                    v-show="itemsTable.length > 0"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    style="margin-right: 5px"
                    @click="exportExcel"
                  >
                    <span class="text-nowrap text-right">Xuất excel</span>
                  </b-button>
                  <b-button
                    v-show="itemsTable.length > 0"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-primary"
                    @click="exportPdfFile"
                  >
                    <b-img
                      :src="require('@/assets/images/icons/file-icons/pdf.png')"
                      height="auto"
                      width="12"
                      class="mr-1"
                    />
                    <span class="align-items-center">Xuất PDF</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="12">
                <div>
                  <b-table
                    ref="table"
                    :bordered="true"
                    :small="true"
                    :hover="true"
                    :items="itemsTable"
                    :fields="fieldsTable"
                  >
                    <template #cell(index)="data">
                      {{ data.index + 1 }}
                    </template>
                  </b-table>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BContainer,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BImg,
  BOverlay,
  BRow,
  BTable,
  VBTooltip,
} from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/extensions
import { getUser } from '@/auth/utils'
import vSelect from 'vue-select'

export default {
  name: 'CreditClassStudentPoint',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BFormInput,
    BImg,
    BButton,
    BCard,
    BCol,
    BContainer,
    BOverlay,
    BRow,
    BFormGroup,
    BForm,
    vSelect,
    BTable,
    BFormCheckbox,
  },
  data() {
    return {
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 1000,
        organizationId: getUser().orgId,
        trainingSystemId: null,
        courseId: null,
        courseSemesterId: null,
        departmentId: null,
        programmeId: null,
        subjectId: null,
        status: null,
        sort: null,
        firstExamTime: 0,
        code: null,
      },
      creditClassId: null,
      fieldsTable: [
        { key: 'index', label: '#' },
        { key: 'student_code', label: 'Mã học viên' },
        { key: 'full_name', label: 'Họ và tên' },
        { key: 'birthday', label: 'Ngày sinh' },
        { key: 'average_point', label: 'Điểm ĐGHP' },
        { key: 'alphabet_point', label: 'Điểm chữ' },
        { key: 'note', label: 'Ghi chú' },
      ],
      disabledSelect: false,
    }
  },
  computed: {
    ...mapGetters({
      itemsTable: 'creditClassStudentPoint/dataLists',
      creditClasses: 'creditClassStudentPoint/creditClasses',
      dropdownSubjects: 'dropdown/subjects',
      courses: 'dropdown/courses',
      trainingSystems: 'dropdown/trainingSystems',
      courseSemesters: 'dropdown/courseSemesters',
      departments: 'dropdown/departments',
      programmes: 'dropdown/programmes',
      programmeSubjects: 'dropdown/programmeSubjects',
      generalScores: 'creditClassStudentPoint/generalScores',
    }),
  },
  watch: {
    async creditClassId(val) {
      if (val) {
        this.filter.code = this.creditClasses.find(e => e.value === val).label
        await this.getGeneralScoresByCreditClassId({ creditClassId: val })
        if (this.generalScores.length > 0) {
          this.fieldsTable = [
            ...[
              { key: 'index', label: '#' },
              { key: 'student_code', label: 'Mã học viên' },
              { key: 'full_name', label: 'Họ và tên' },
              { key: 'birthday', label: 'Ngày sinh' },
            ],
            ...this.generalScores,
            ...[
              { key: 'first_time_score', label: 'Điểm thi L1' },
              { key: 'average_point', label: 'Điểm ĐGHP' },
              { key: 'alphabet_point', label: 'Điểm chữ' },
              { key: 'note', label: 'Ghi chú' },
            ],
          ]
        } else {
          this.fieldsTable = [
            { key: 'index', label: '#' },
            { key: 'student_code', label: 'Mã học viên' },
            { key: 'full_name', label: 'Họ và tên' },
            { key: 'birthday', label: 'Ngày sinh' },
            { key: 'average_point', label: 'Điểm ĐGHP' },
            { key: 'alphabet_point', label: 'Điểm chữ' },
            { key: 'note', label: 'Ghi chú' },
          ]
        }
        await this.onSelect()
      }
    },
  },
  async created() {
    const { creditClassId, creditClassCode } = this.$route.query
    if (creditClassId) {
      this.disabledSelect = true
      this.setCreditClasses({
        data: [{ value: creditClassId, label: creditClassCode }],
      })
      this.creditClassId = creditClassId
    } else {
      this.isLoading = true
      try {
        const cUser = getUser()
        this.filter.organizationId = (cUser.orgId ? cUser.orgId : 0)
        await Promise.all([
          this.getTrainingSystems({ organizationId: this.filter.organizationId }),
          this.getDepartments({ organizationId: this.filter.organizationId }),
        ])
        if (this.trainingSystems.length > 0) {
          this.filter.trainingSystemId = this.trainingSystems[0].value
          await this.getCourses(this.filter)
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    }
  },
  methods: {
    ...mapMutations({
      setCreditClasses: 'creditClassStudentPoint/SET_CREDIT_CLASSES',
    }),
    ...mapActions({
      getData: 'creditClassStudentPoint/getData',
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getCourses: 'dropdown/getCourses',
      getCourseSemesters: 'dropdown/getCourseSemesters',
      getDepartments: 'dropdown/getDepartments',
      getMajors: 'dropdown/getMajors',
      getProgrammes: 'dropdown/getProgrammes',
      getProgrammeSubjects: 'dropdown/getProgrammeSubjects',
      getCreditClasses: 'creditClassStudentPoint/getCreditClasses',
      getDropdownSubjects: 'dropdown/getSubjects',
      exportPdf: 'creditClassStudentPoint/exportPdf',
      getGeneralScoresByCreditClassId: 'creditClassStudentPoint/getGeneralScoresByCreditClassId',
    }),
    async onTrainingSystemsChange(event) {
      this.filter.trainingSystemId = event
      this.filter.courseId = null
      await this.getCourses(this.filter)
    },
    async onCoursesChange(event) {
      this.filter.courseId = event
      this.filter.courseSemesterId = null
      await this.getCourseSemesters({ organizationId: getUser().orgId, courseId: this.filter.courseId })
    },
    async onDepartmentChange(event) {
      this.filter.departmentId = event
      await this.getDropdownSubjects({ departmentId: event, organizationId: getUser().orgId })
    },
    async onCreditClassCodeChange(event) {
      this.filter.code = event
      await this.getCreditClasses(this.filter)
      if (this.creditClasses.length > 0) {
        const found = this.creditClasses.find(item => item.label === this.filter.code)
        if (found) {
          this.creditClassId = found.value
        } else {
          this.creditClassId = null
          this.itemsTable = []
        }
      } else {
        this.creditClassId = null
        this.itemsTable = []
      }
    },
    async onSubjectChange(event) {
      this.filter.subjectId = event
      await this.getCreditClasses(this.filter)
      if (this.creditClasses.length > 0) {
        this.creditClassId = this.creditClasses[0].value
      } else {
        this.creditClassId = null
      }
    },
    async onSelect() {
      this.isLoading = true
      try {
        await this.getData({
          credit_class_id: this.creditClassId,
          firstExamTime: this.filter.firstExamTime,
          creditClassCode: this.filter.code ? this.filter.code : null,
          scoringFormulaId: this.scoringFormulaId,
        })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },

    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    exportExcel() {
      this.isLoading = true
      if (this.itemsTable.length > 0) {
        const headerScores = this.generalScores.map(item => item.label)
        const keyScores = this.generalScores.map(item => item.key)
        try {
          const tHeader = [
            ...['Mã học viên', 'Họ và tên', 'Ngày sinh'],
            ...headerScores,
            ...['Điểm thi L1', 'Điểm ĐGHP', 'Điểm chữ', 'Ghi chú'],
          ]
          const filterVal = [
            ...['student_code', 'full_name', 'birthday'],
            ...keyScores,
            ...['first_time_score', 'average_point', 'alphabet_point', 'note'],
          ]
          const dataJson = this.formatJson(filterVal, this.itemsTable)
          import('@/utils/Export2Excel').then(excel => {
            excel.export_json_to_excel({
              header: tHeader,
              data: dataJson,
              filename: 'Bảng_điểm_tổng_hợp_đánh_giá_học_phần',
              autoWidth: true,
              bookType: 'xlsx',
            })
          })
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Chưa thực hiện lấy danh sách phách',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        this.isLoading = false
      }
    },
    async exportPdfFile() {
      if (this.itemsTable.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Không có dữ liệu để xuất file!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.isLoading = true
      try {
        const data = await this.exportPdf({
          creditClassId: this.creditClassId,
          firstExamTime: this.filter.firstExamTime,
          creditClassCode: this.filter.code,
          scoringFormulaId: this.scoringFormulaId,
        })
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
          window.open(url, '_blank')
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File không có dữ liệu',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
